import service from '@/until/api'

export function getYesterdayOperationData() {
    return service({
        url: '/data-manage/screen/getYesterdayOperationData',
        method: 'get'
    })
}
// 获取车辆累计信息
export function getCumulativeData() {
    return service({
        url: '/data-manage/screen/getCumulativeData',
        method: 'get'
    })
}

// 车辆接入分布概览
export function getVehicleDistributionData() {
    return service({
        url: '/data-manage/screen/getVehicleDistributionData',
        method: 'get'
    })
}

// 车辆报警信息
export function getAlarmData() {
    return service({
        url: '/data-manage/screen/getAlarmData',
        method: 'get'
    })
}

// 获取前七天运营的数据
export function getWeekOperationData() {
    return service({
        url: '/data-manage/screen/getWeekOperationData',
        method: 'get'
    })
}

// 获取地图数据
export function getLocationInfo(params) {
    return service({
        url: '/data-manage/screen/getLocationInfo',
        method: 'get',
        params
    })
}
// 获取地图上车辆分布信息
export function getMapDistributionData(params) {
    return service({
        url: '/data-manage/screen/getMapDistributionData',
        method: 'get',
        params
    })
}